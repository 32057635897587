<template>
  <div>
    <!-- mobile -->
    <template v-if="isMobile">
      <v-toolbar fixed>
        <v-toolbar-title class="d-flex">
          <img
            src="/minnowtech.png"
            class="mr-2 mb-1 pointer"
            contain
            max-width="100"
            width="160"
            max-height="78"
            @click="goHome()"
          />
          <span style="font-size:11px; padding-bottom:20px;">Portal</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        
        <template v-if="loggedIn">
        <v-menu right bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            </v-btn>
          </template>
          
          <div>
            <div class="ml-5">
              <v-btn color="primary" text href="/ponds"> Pond List </v-btn>
            </div>
            <!-- <div class="ml-5">
              <v-btn color="primary" text href="/howto"> Guideline </v-btn>
            </div> -->
            <div v-if="isMinnowtech" class="ml-5">
              <v-btn color="primary" text href="/scan"> Upload Scan </v-btn>
            </div>
            <div v-if="isMinnowtech" class="ml-5">
              <v-btn color="primary" text href="/scandata"> Upload Data </v-btn>
            </div>
            <v-btn color="primary" @click="logout" class="ml-3">LogOut </v-btn>
          </div>
        </v-menu>
        </template>
      </v-toolbar>
      <v-navigation-drawer
        v-model="drawer"
        absolute
        right
        temporary
        app
      >
        <template v-slot:prepend>
          <v-list-item two-line>
            <v-list-item-avatar>
              <img v-if="user_info && user_info.farmname=='Minnowtech'"
                :src="avatar_url + 'minnowtech.jpg'"
              >
              <img v-else-if="user_info && user_info.farmname"
                :src="avatar_url + user_info.farmavatar"
              >
              <v-icon v-else size="50">
                mdi-account-circle
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="name">{{user_info && user_info.username}}</v-list-item-title>
              <v-list-item-subtitle>{{ user_info && user_info.farmname }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-divider></v-divider>
        <v-list
          nav
          dense
          link
        >
          <v-list-item-group
            v-model="group"
            active-class="deep-purple--text text--accent-4"
          >
            <v-list-item to="/ponds">
              <v-list-item-title>Pond List</v-list-item-title>
            </v-list-item>

            <v-list-item v-if="isMinnowtech" to="/scan">
              <v-list-item-title>Upload Scan</v-list-item-title>
            </v-list-item>

            <v-list-item v-if="isMinnowtech" to="/scandata">
              <v-list-item-title>Upload Data</v-list-item-title>
            </v-list-item>
            <div class="pa-2 mt-7">
              <v-btn color="primary" block @click="logout" class="">LogOut </v-btn>
            </div>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </template>
    <!-- desktop -->
    <template v-else>
      <v-app-bar id="home-app-bar" app elevation="5" height="80" style="background: #fff">
        <img
          src="/minnowtech.png"
          class="mr-3 mb-1 pointer"
          contain
          max-width="170"
          width="200"
          max-height="78"
          @click="goHome()"
        />
        <span style="font-size:13px; padding-bottom:20px;">Portal</span>
        <template v-if="loggedIn">
          <div class="ml-5">
            <v-btn color="primary" max-width="110" text href="/ponds"> Pond List </v-btn>
          </div>
          <!-- <div class="ml-5">
            <v-btn color="primary" max-width="110" text href="/howto"> Guideline </v-btn>
          </div> -->
          <div v-if="isMinnowtech" class="ml-1">
            <v-btn color="orange" max-width="120" text href="/scan"> Upload Scan </v-btn>
          </div>
          <div v-if="isMinnowtech" class="ml-1">
            <v-btn color="orange" max-width="120" text href="/scandata"> Upload Data </v-btn>
          </div>
        </template>
        <v-spacer />

        <!-- logged -->
        <template v-if="loggedIn">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" text v-bind="attrs" v-on="on">
              </v-btn>            
            </template>
            <v-list>
              <v-list-item key="menu1">
                <v-list-item-title
                  ><router-link to="/mypage">
                    My profile
                  </router-link></v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
          <v-avatar
            size="43"
            class="mr-3 mt-2"
          >
            <img v-if="user_info && user_info.farmname=='Minnowtech'"
              :src="avatar_url + 'minnowtech.jpg'"
            >
            <img v-else-if="user_info && user_info.farmname"
              :src="avatar_url + user_info.farmavatar"
            >
            <v-icon v-else size="50">
              mdi-account-circle
            </v-icon>
          </v-avatar>
          <div class="mr-6">
            <div class="name">{{ user_info && user_info.username}}</div>
            <div class="farm">{{ user_info && user_info.farmname }}</div>
          </div>
          <v-btn color="primary" @click="logout" class="ml-3">LogOut </v-btn>
        </template>
        <!-- not logged -->
        <template v-else>
          <!-- <div>
            <div>
              <router-link to="/mypage" style="text-decoration: underline">
                <v-btn color="primary" type="submit">Login </v-btn>
              </router-link>
            </div>
          </div> -->
        </template>
      </v-app-bar>
    </template>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from "vuex";

export default {
  name: "HomeAppBar",

  data: () => ({
    avatar_url: "https://farm-avatar.s3.amazonaws.com/",
    drawer: false,
    group: null,
    items: ["project"],
  }),
  watch: {
    group () {
      this.drawer = false
    },
  },
  computed: {
    ...mapState(["isMobile", "user_info", "isMinnowtech"]),
    ...mapGetters(["loggedIn"]),
    // isMobile() {
    //   return this.$vuetify.breakpoint.smAndDown;
    // },
  },
  mounted () {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },
  methods: {
    ...mapMutations(["logout"]),

    onResize () {
      this.$store.commit("isMobile", window.innerWidth < 800)
    },
    goHome() {
      this.$store.commit("pond", null);
      this.$router.push('/')
    },
  },
};
</script>

<style lang="sass">
#home-app-bar
  .v-tabs-slider
    max-width: 24px
    margin: 0 auto

    .v-tab
      &::before
        display: none
</style>
<style scoped>
  .name {
    font-weight: bold;
    font-size: 25px;
  }
  .farm {
    color: #8f98a0;
    font-size: 14px;
  }
</style>
